import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
    AppBar, Toolbar, Typography, Button, Container, Grid, Card, CardContent,
    TextField, CircularProgress, Snackbar, IconButton, Box, Paper, Chip, Avatar,
    Fade, useMediaQuery, CssBaseline
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Book, Mail, CheckCircle, Person, Menu, Code, Psychology, Biotech } from '@mui/icons-material';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

// Firebase config (unchanged)
const firebaseConfig = {
    apiKey: "AIzaSyCsffo_l0iWw4n1BUZSXaOVcVJVTQa0JUs",
    authDomain: "book-promo-210a0.firebaseapp.com",
    projectId: "book-promo-210a0",
    storageBucket: "book-promo-210a0.appspot.com",
    messagingSenderId: "819376219908",
    appId: "1:819376219908:web:845021b13e446b553f5251",
    measurementId: "G-WD7VLRXNDC"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Create a custom theme
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#4fc3f7', // A lighter blue for better contrast
        },
        secondary: {
            main: '#ff80ab', // A lighter pink for better contrast
        },
        background: {
            default: '#1a1a1a', // Slightly lighter than pure black
            paper: '#2c2c2c', // Lighter background for cards and other elements
        },
        text: {
            primary: '#ffffff', // Pure white for maximum contrast
            secondary: '#e0e0e0', // Light grey for secondary text
        },
    },
    typography: {
        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '3.5rem',
            fontWeight: 700,
            letterSpacing: '-0.01em',
        },
        h4: {
            fontWeight: 600,
            letterSpacing: '0.01em',
        },
        h5: {
            fontWeight: 500,
            letterSpacing: '0.01em',
        },
        body1: {
            fontSize: '1.1rem',
            letterSpacing: '0.01em',
            lineHeight: 1.7,
        },
        body2: {
            letterSpacing: '0.01em',
            lineHeight: 1.6,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    textTransform: 'none',
                    fontWeight: 500,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                },
            },
        },
    },
});

const BookLandingPage = () => {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');  // This line is correct, but let's double-check the usage
    const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            const targetDate = new Date('2024-08-31');
            const difference = targetDate.getTime() - now.getTime();

            setCountdown({
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await addDoc(collection(db, 'subscribers'), {
                email: email,
                timestamp: new Date(),
                status: "active"
            });
            setIsSubmitted(true);
            setEmail('');
            setOpenSnackbar(true);
        } catch (err) {
            console.error("Error adding document: ", err);
            setError('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const features = [
        {
            icon: <Code />,
            text: "Comprehensive AI history",
            body: "Embark on a journey through time, from the ancient roots of artificial intelligence to today's cutting-edge developments. Discover how early computing pioneers laid the groundwork for modern AI, and trace the evolution of key concepts like machine learning and neural networks. This book offers a rich tapestry of AI's milestones, setbacks, and breakthroughs, providing crucial context for understanding our current AI-driven world."
        },
        {
            icon: <Psychology />,
            text: "Cutting-edge AI applications",
            body: "Dive into the transformative world of modern AI applications. From healthcare diagnostics and autonomous vehicles to personalized recommendations and natural language processing, explore how AI is revolutionizing industries and reshaping our daily lives. Gain insights into the latest breakthroughs in computer vision, robotics, and AI-powered decision-making systems that are pushing the boundaries of what's possible."
        },
        {
            icon: <Biotech />,
            text: "Ethical considerations",
            body: "Delve into the complex ethical landscape of AI development and deployment. Examine critical issues such as algorithmic bias, privacy concerns, job displacement, and the long-term implications of artificial general intelligence. This section challenges readers to think critically about the responsible creation and use of AI, exploring frameworks for ethical AI design and the importance of transparency and accountability in AI systems."
        },
        {
            icon: <CheckCircle />,
            text: "Future predictions",
            body: "Peer into the potential futures shaped by artificial intelligence. From the possibility of artificial general intelligence to the integration of AI in governance and space exploration, explore expert predictions and speculative scenarios. Discover how AI might transform education, creativity, and human cognitive enhancement, and ponder the long-term implications for society, work, and what it means to be human in an AI-augmented world."
        }
    ];

    const NavItems = () => (
        <>
            <Button color="inherit" href="#about">About</Button>
            <Button color="inherit" href="#features">Features</Button>
            <Button color="inherit" href="#author">Author</Button>
            {/*<Button color="secondary" variant="contained" href="#notify">Pre-order Now</Button>*/}
        </>
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}>
                <AppBar position="fixed" color="transparent" elevation={0}>
                    <Toolbar>
                        <img
                            src="/Body.png"
                            alt="AI Book Logo"
                            style={{
                                height: '100px',  // Adjust this value as needed
                                marginRight: '10px'
                            }}
                        />
                        {isMobile ? (
                            <IconButton color="inherit" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <Menu />
                            </IconButton>
                        ) : (
                            <NavItems />
                        )}
                    </Toolbar>
                </AppBar>

                <Fade in={mobileMenuOpen && isMobile}>
                    <Box sx={{ position: 'fixed', top: 64, left: 0, right: 0, bgcolor: 'background.paper', zIndex: 1000 }}>
                        <NavItems />
                    </Box>
                </Fade>

                <Container maxWidth="lg" sx={{ pt: 15, pb: 8 }}>
                    <Grid container spacing={6} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8 }}
                            >
                                <Typography variant="h1" component="h1" gutterBottom>
                                    AI: From Myth to Reality
                                </Typography>
                                <Typography variant="h5" color="textSecondary" paragraph>
                                    Uncover the Past, Present, and Future of Artificial Intelligence
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    href="#notify"
                                    startIcon={<Mail />}
                                    sx={{ mt: 2 }}
                                >
                                    Get Notified
                                </Button>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8, rotate: -5 }}
                                animate={{ opacity: 1, scale: 1, rotate: 0 }}
                                transition={{ duration: 0.8, type: "spring", stiffness: 100 }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        perspective: '1000px',
                                        transformStyle: 'preserve-3d',
                                        '&:hover': {
                                            '& > div': {
                                                transform: 'rotateY(10deg)',
                                            },
                                        },
                                    }}
                                >
                                    <Paper
                                        elevation={8}
                                        sx={{
                                            position: 'relative',
                                            overflow: 'hidden',
                                            borderRadius: 4,
                                            transition: 'transform 0.3s ease-in-out',
                                            transform: 'rotateY(0deg)',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                background: 'linear-gradient(45deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0.1) 100%)',
                                                zIndex: 2,
                                            },
                                        }}
                                    >
                                        <img
                                            src="/ai_from_myth_to_reality.png"
                                            alt="AI: From Myth to Reality Book Cover"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                display: 'block',
                                                boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 16,
                                                right: 16,
                                                bgcolor: 'secondary.main',
                                                color: 'white',
                                                borderRadius: '50%',
                                                width: 80,
                                                height: 80,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                boxShadow: 3,
                                                zIndex: 3,
                                                transform: 'rotate(15deg)',
                                            }}
                                        >
                                            <Typography variant="h6" align="center">
                                                Coming<br />Soon
                                            </Typography>
                                        </Box>
                                    </Paper>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: -20,
                                            left: '10%',
                                            width: '80%',
                                            height: '20px',
                                            background: 'rgba(0,0,0,0.2)',
                                            filter: 'blur(10px)',
                                            borderRadius: '50%',
                                            zIndex: 1,
                                        }}
                                    />
                                </Box>
                            </motion.div>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 12, mb: 8, textAlign: 'center' }}>
                        <Typography variant="h4" gutterBottom>
                            Coming Soon
                        </Typography>
                        <Grid container spacing={2} justifyContent="center">
                            {Object.entries(countdown).map(([unit, value]) => (
                                <Grid item xs={6} sm={3} key={unit}>
                                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                                        <Card raised sx={{ bgcolor: 'primary.main', color: 'white' }}>
                                            <CardContent>
                                                <Typography variant="h3" align="center">{value}</Typography>
                                                <Typography variant="body1" align="center" sx={{ textTransform: 'uppercase' }}>
                                                    {unit}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </motion.div>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Box id="notify" sx={{ mt: 12, scrollMarginTop: 80 }}>
                        <Typography variant="h4" gutterBottom align="center">
                            Get Notified
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} alignItems="flex-end" justifyContent="center">
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Email Address"
                                        variant="outlined"
                                        type="email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        type="submit"
                                        disabled={isLoading}
                                        startIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : <Mail />}
                                    >
                                        {isLoading ? 'Submitting...' : 'Notify Me'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>

                    <Box id="about" sx={{ mt: 12, scrollMarginTop: 80 }}>
                        <Typography variant="h4" gutterBottom align="center">
                            About the Book
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" paragraph>
                                    Embark on a captivating journey through the world of Artificial Intelligence.
                                    From its mythical origins to its current reality and beyond, this book
                                    explores the fascinating evolution and profound impact of AI on our society.
                                </Typography>
                                <Typography variant="body1">
                                    Delve into the past, uncover the present, and glimpse into the future of this
                                    transformative technology.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {['Machine Learning', 'Neural Networks', 'Deep Learning', 'Natural Language Processing', 'Computer Vision', 'Robotics'].map((topic) => (
                                        <Chip key={topic} label={topic} color="primary" variant="outlined" />
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box id="features" sx={{ mt: 8, scrollMarginTop: 80 }}>
                        <Typography variant="h4" gutterBottom align="center">
                            Key Features
                        </Typography>
                        <Grid container spacing={2}>
                            {features.map((feature, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <Card sx={{ height: '100%' }}>
                                        <CardContent>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                                {feature.icon}
                                                <Typography variant="h6" sx={{ ml: 1 }}>
                                                    {feature.text}
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" color="text.secondary">
                                                {feature.body}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Box id="author" sx={{ mt: 12, scrollMarginTop: 80 }}>
                        <Typography variant="h4" gutterBottom align="center">About the Author</Typography>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        src="/author.png"
                                        alt="AI Book Logo"
                                        style={{
                                            height: '300px',  // Adjust this value as needed
                                            marginRight: '10px'
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="h5" gutterBottom>
                                    Radoslav Dodnikov
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    Software Engineer & Author
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    Radoslav Dodnikov is a seasoned software engineer with a passion for artificial intelligence and its implications on society. With years of experience in the tech industry, he brings a unique perspective to the complex world of AI, making it accessible to both experts and newcomers alike.
                                </Typography>
                                <Typography variant="body1">
                                    In "AI: From Myth to Reality," Radoslav combines his technical expertise with engaging storytelling to guide readers through the fascinating journey of AI's evolution and its potential future impact.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    message="Thank you! We'll keep you updated."
                />
            </Box>
        </ThemeProvider>
    );
};

export default BookLandingPage;